import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <img className="logo" alt="KLOS" src="/images/klos-logo-classic@2x.png" />
    </header>
  );
};

export default Header;