import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="container home">
        <h1 className="headline">Personalize your own KLOS logo</h1>
        <div className="logo-selection__wrapper">
          <h3>Choose logo:</h3>
          <div className="logo-selection__logos">
            <Link to="/classic"><img className="logo" alt="KLOS Classic Logo" src="/images/klos-logo-classic@2x.png" /></Link>
            <Link to="/anniversary"><img className="logo" alt="KLOS 50th Aniiversary Logo" src="/images/klos-logo-anniversary@2x.png" /></Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;