import React, { Component, Fragment } from 'react'
import ReactGA from 'react-ga';

export default class LogoGenerator extends Component {

  componentDidMount() {
    const canvas = document.createElement("canvas");
    canvas.width = 1000;
    canvas.height = 400;
    const ctx = canvas.getContext("2d")
    const img = this.refs.image;
    const rectHeight = 140;
    const rectWidth = 655;
    const rectX = 171;
    const rectY = 120;
    const text = this.props.text.toUpperCase();

    img.onload = () => {
      ctx.drawImage(img, 0, 0)
      ctx.fillStyle = (this.props.logoType === 'classic') ? '#FFFFFF' : '#D6BF53';
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      if (this.props.logoType === 'classic') {
        ctx.font = "bold 35px Arial";
        ctx.fillText("95.5 KLOS", rectX + (rectWidth / 2), rectHeight + 130);
      }
      fitTextOnCanvas(text);
      const dataURI = canvas.toDataURL('image/png');
      ReactGA.event({
        category: 'User',
        action: `Created Logo: ${text}`
      });
      this.props.uploadImage(dataURI);
    }

    const fitTextOnCanvas = (text) => {
      var fontsize = 130;
      do {
        fontsize--;
        ctx.font = `bold ${fontsize}px Arial`;
      } while (ctx.measureText(text).width > rectWidth)
      ctx.fillText(text, rectX + (rectWidth / 2), rectY + (rectHeight / 2));
    }
  }

  render() {
    const { logo } = this.props;
    return (
      <Fragment><img ref="image" src={logo} alt="95.5 KLOS" className="hidden" /></Fragment>
    );
  }
}
