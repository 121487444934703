import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <span>Powered by <a rel="noopener noreferrer" href="https://jacapps.com/" target="_blank">jācapps</a></span>
    </footer>
  );
};

export default Footer;