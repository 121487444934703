import React, { Component, Fragment } from 'react'
import axios from 'axios';
import Filter from 'bad-words';
import TopBarProgress from "react-topbar-progress-indicator"
import LogoGenerator from '../components/LogoGenerator';
import Footer from '../components/Footer';
import LinkButton from '../components/LinkButton';
import Icon from '../components/Icon';
import logo from '../components/logos/klos-blank-anniversary.png';


const badWords = ['KROQ', 'Alt', '98.7', '106.7', '93.1', 'Trump', 'Biden', 'Sucks'];
const filter = new Filter();
filter.addWords(...badWords);
filter.removeWords('Dick');

TopBarProgress.config({
  barThickness: 10,
  barColors: {
    "0": "#0081c9",
    "0.25": "#d31367",
    "0.5": "#df582a",
    "0.75": "#fbe200",
    "1.0": "#6abf4a"
  },
  shadowBlur: 5,
})

const defaultState = {
  text: undefined,
  obscene: false,
  cleared: false,
  loading: false,
  imgUrl: undefined,
  msg: undefined,
}

export default class Anniversary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...defaultState,
    }
  }

  handleChange = (e) => {
    this.setState({ text: e.target.value, obscene: true, msg: undefined });
  }

  submit = (e) => {
    e.preventDefault();
    const { text } = this.state;
    const obscene = filter.isProfane(text);
    if (!text || text.trim().trim().length === 0) {
      this.refs.infield.value = null;
      this.setState({
        obscene: true,
        cleared: false,
        msg: 'Please provide a name.'
      });
    } else if (obscene) {
      this.setState({
        obscene: true,
        cleared: false,
        msg: 'Sorry, one or more of those words are not allowed.'
      });
    } else {
      this.setState({ cleared: true });
    }
  }

  sendFormData = async (image, name) => {
    var fd = new FormData();
    fd.append('file', image, name);
    return await axios.post(`/create`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  generateIgLogo = async (image) => {
    //create canvas
    const canvas = document.createElement("canvas");
    canvas.width = 1080;
    canvas.height = 1080;
    const ctx = canvas.getContext("2d");
    const drawLogo = new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;
      img.crossOrigin = "anonymous";
      img.onload = () => {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img,
          canvas.width / 2 - img.width / 2,
          canvas.height / 2 - img.height / 2
        );
        resolve('Successfully generate IG');
      }
    });
    const blob = drawLogo.then(() => {
      return canvas.toDataURL('image/png');
    });
    return blob;
  }

  dataURItoBlob = async (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  }

  uploadImage = async (image) => {
    try {
      this.setState({ loading: true });
      const name = `${Date.now().toString()}_${Math.floor(Math.random() * 1000000000)}`;
      const lgBlob = await this.dataURItoBlob(image);
      const request = await this.sendFormData(lgBlob, `${name}_lg`);
      const imgUrl = await request.data.Location;
      const instagram = await this.generateIgLogo(image);
      const igBlob = await this.dataURItoBlob(instagram);
      const requestIg = await this.sendFormData(igBlob, `${name}_ig`);
      const igImgUrl = await requestIg.data.Location;
      if (igImgUrl) {
        this.setState({
          loading: false,
          url: name,
          imgUrl,
          igImgUrl,
        });
      } else {
        throw new Error({ 'msg': 'Did not return image url.' });
      }

    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  render() {
    const { text, msg, cleared, imgUrl, igImgUrl, loading, url } = this.state;
    return (
      <Fragment>
        {loading &&
          <TopBarProgress />
        }
        <div className="wrapper">
          <div className="container logo-generator">
            {!imgUrl &&
              <Fragment>
                <img className="hero-logo" src="/images/klos-logo-anniversary@2x.png" alt="KLOS" />
                <form autoComplete="off">
                  <div className="name-field">
                    <input ref="infield" name="text" type="text" onChange={this.handleChange} placeholder="Enter your name" />
                    <p className="error-message">{msg} &nbsp;</p>
                  </div>
                  <button disabled={loading} onClick={this.submit} className="btn">Create</button>
                </form>
                {cleared &&
                  <LogoGenerator text={text} logo={logo} logoType="anniversary" uploadImage={this.uploadImage} />
                }
              </Fragment>
            }
            {imgUrl &&
              <Fragment>
                <div className="generated-logo__wrapper">
                  <img className="generated-logo__logo" src={imgUrl} alt="KLOS" />
                  <div className="generated-logo__options">
                    <div className="op-container">
                      <span>Download:</span>
                      <span><a rel="noopener noreferrer" target="_blank" href={imgUrl} download><Icon name="download" /></a></span>
                      <span><a rel="noopener noreferrer" target="_blank" href={igImgUrl} download><Icon name="instagram" /></a></span>
                    </div>
                    <div className="op-container">
                      <span>Share:</span>
                      <span><a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://klos.jacapps.com/share/${url}`}><Icon name="facebook" /></a></span>
                      <span><a rel="noopener noreferrer" target="_blank" href={`https://twitter.com/intent/tweet?text=KLOS%2050th%20Anniversary%3A%20Generate%20your%20own%20KLOS%20logo!&url=https://klos.jacapps.com/share/${url}`}><Icon name="twitter" /></a></span>
                    </div>
                  </div>
                </div>
                <LinkButton to="/" className="btn">Do It Again</LinkButton>
              </Fragment>
            }
          </div>
          <Footer />
        </div>
      </Fragment>
    )
  }
}