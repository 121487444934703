import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './styles/App.scss';
import Home from './layouts/Home';
import Classic from './layouts/Classic';
import Anniversary from './layouts/Anniversary';

ReactGA.initialize('UA-2358575-15');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/classic" exact component={Classic} />
          <Route path="/anniversary" exact component={Anniversary} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
